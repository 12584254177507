@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hero-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.left-shapes {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
}

.shape1,
.shape2 {
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  transform-origin: left;
  animation: slideIn 1s forwards;
}

.shape1 {
  animation-delay: 0.5s;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.background {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.container {
  display: flex;
  width: 400px;
  height: 200px;
  background-color: #f0f0f0;
}


.trapezium {
  flex: 2;
  clip-path: polygon(0% 0, 100% 0, 75% 100%, 0% 100%);
}
.trapezium2 {
 
      clip-path: polygon(75% 0, 100% 0, 100% 100%, 0% 100%);
      margin-left: 50px; /* Adjust as needed */
}
@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-up {
  animation: fade-in-up 1s ease-out;
}

.animate-fade-in-up.delay-1s {
  animation: fade-in-up 1s ease-out 1s;
}